  <template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
        <v-card v-if="data_loaded">
          <v-card-title class="align-center">
            <div class="d-flex align-center">
              <v-icon x-large>redeem</v-icon>
              <h1 class="ms-3">מבצעים</h1>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$store.getters.permission('campaigns create')"
              color="primary"
              to="/campaign"
              >מבצע חדש</v-btn
            >
          </v-card-title>
          <v-data-table
            :mobile-breakpoint="0"
            :hide-default-footer="true"
            :disable-pagination="true"
            :items="items"
            :headers="headers"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                class="px-2"
                x-small
                :color="item.status == 1 ? 'success black--text' : 'error'"
              >
                {{ item.status == 1 ? "פעיל" : "לא פעיל" }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="$store.getters.permission('campaigns edit')"
                :to="`/campaign/${item.id}`"
                small
                icon
              >
                <v-icon small>edit</v-icon>
              </v-btn>

              <v-btn
                small
                icon
                v-if="$store.getters.permission('campaigns delete')"
              >
                <v-icon @click="delete_row(item.id)" small>delete</v-icon>
              </v-btn>
            </template>
            <!--/ actions -->
          </v-data-table>
        </v-card>
        <DeleteDialog
          v-if="$store.getters.permission('campaigns delete')"
          @delete_done="get_data()"
          @close_delete="delete_obj.delete_prop = false"
          :delete_obj="delete_obj"
        ></DeleteDialog>
        <template>
          <div
            style="margin-bottom: 100px"
            v-if="data_loaded"
            class="text-center mt-4 d-flex justify-center"
          >
            <v-pagination
              @input="get_data()"
              v-model="page"
              total-visible="7"
              :length="total"
              circle
            ></v-pagination>
          </div>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  name: "employees_page",
  data() {
    return {
      page: 0,
      data_loaded: false,
      items: [],
      total: 0,
      delete_obj: {
        delete_prop: false,
        delete_url: "campaigns",
        title: "מחק מבצע",
        id: null,
      },
      headers: [
        {
          value: "created_at",
          text: "תאריך",
          align: "center",
        },
        {
          value: "campaign_name",
          text: "שם מבצע",
          align: "center",
        },
        {
          value: "start_date",
          text: "תאריך התחלה",
          align: "center",
        },
        {
          value: "end_date",
          text: "עד תאריך",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },

        {
          value: "actions",
          text: "פעולות",
          align: "center",
        },
      ],
    };
  },
  components: {
    DeleteDialog,
  },

  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `campaigns?page=${this.page}`,
            method: "get",
          },
        })
        .then((res) => {
          this.items = Object.assign([], res.data.data);
          this.total = res.data.meta.last_page;
          this.page = res.data.meta.current_page;
          this.data_loaded = true;
          console.log(res);
        });
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
